import React from "react";
import { graphql, Link } from "gatsby";
import { kebabCase } from "lodash";
import { GatsbyImage } from "gatsby-plugin-image";
import { FormattedMessage } from "react-intl";

import withLayout from "../layout";
import SEO from "../components/seo";

import "./entryDetail.scss";

const EntryDetail = (props) => {
    const { markdownRemark } = props.data;
    const { prev, next } = props.pageContext;
    const { frontmatter, html } = markdownRemark;
    const TAG_TITLE = frontmatter.type === "project" ? "Stack" : "Categories";

    return (
        <div>
            <SEO title={frontmatter.title} keywords={[...frontmatter.tags]} />
            <main className={`detail ${frontmatter.type}-detail`} role="main">
                <article
                    className="container"
                    id="content"
                    itemScope
                    itemType="http://schema.org/ItemPage"
                >
                    <div className="pure-g-r">
                        <div className="pure-u-1-2">
                            <div className="cover-wrapper">
                                <GatsbyImage
                                    image={
                                        frontmatter.thumbnail.childImageSharp
                                            .gatsbyImageData
                                    }
                                />
                            </div>
                            <section className="stack-wrapper">
                                <h3 className="h3 detail-stack">{TAG_TITLE}</h3>
                                <div className="tag-pool" itemProp="keywords">
                                    {frontmatter.tags.map((tag) => (
                                        <span key={tag + `tag`}>
                                            <Link
                                                className="tag"
                                                itemProp="specialty keywords"
                                                to={`/tags/${kebabCase(tag)}/`}
                                            >
                                                {tag}
                                            </Link>
                                        </span>
                                    ))}
                                </div>
                            </section>
                        </div>
                        <div className="pure-u-1-2">
                            <section className="metadata-wrapper">
                                <header className="detail-hd">
                                    <h2
                                        className="h1 detail-title"
                                        itemProp="name"
                                    >
                                        {frontmatter.title}
                                    </h2>
                                    {frontmatter.type === "post" && (
                                        <h3
                                            className="h4 detail-published"
                                            itemProp="publisher author"
                                        >
                                            Last published on {frontmatter.date}{" "}
                                            by Marcos Iglesias
                                        </h3>
                                    )}
                                    {frontmatter.type === "project" && (
                                        <h3
                                            className="h4 detail-company"
                                            itemProp="publisher author"
                                        >
                                            {frontmatter.company}
                                        </h3>
                                    )}
                                    {frontmatter.type === "talk" && (
                                        <h3
                                            className="h4 detail-published"
                                            itemProp="publisher author"
                                        >
                                            Presented on {frontmatter.date}
                                        </h3>
                                    )}
                                </header>
                                <div
                                    className="detail-bd"
                                    itemProp="description"
                                >
                                    <section
                                        dangerouslySetInnerHTML={{
                                            __html: html,
                                        }}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                    <div className="detail-navigation">
                        {prev && (
                            <Link
                                className="link-prev link"
                                to={prev.node.fields.slug}
                            >
                                <span aria-hidden>{"<"}</span>{" "}
                                <FormattedMessage id="common.nav-prev" />
                            </Link>
                        )}
                        {next && (
                            <Link
                                className="link-next link"
                                to={next.node.fields.slug}
                            >
                                <FormattedMessage id="common.nav-next" />{" "}
                                <span aria-hidden>{">"}</span>
                            </Link>
                        )}
                    </div>
                </article>
            </main>
        </div>
    );
};

const customProps = {
    localeKey: "blog",
};

export default withLayout(customProps)(EntryDetail);

export const pageQuery = graphql`
    query ($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                title
                tags
                company
                type
                thumbnail {
                    childImageSharp {
                        gatsbyImageData(width: 600, layout: CONSTRAINED)
                    }
                }
            }
        }
    }
`;

/*
<div className="pure-g-r ptl">
    <div className="pure-u-1 clearfix">
        <aside className="testimonial-wrapper">
            <span className="testimonial-img" data-alt="Ben Rigby, CEO of Sparked.com Picture" data-picture=""><span data-src="http://s3.amazonaws.com/mivstorage/app/public/avatars/32/thumb/thumb.png" itemProp="image" role="image"><img alt="Ben Rigby, CEO of Sparked.com Picture" src="http://s3.amazonaws.com/mivstorage/app/public/avatars/32/thumb/thumb.png"></span> <span data-media="(min-device-pixel-ratio: 2.0)" data-src="http://s3.amazonaws.com/mivstorage/app/public/avatars/32/thumbhd/thumb.png" itemProp="image"></span> <!-- Fallback content for non-JS browsers. Same img src as the initial, unqualified source element. -->
             <noscript><img alt="Ben Rigby, CEO of Sparked.com Image" itemProp="thumbnailUrl" src="http://s3.amazonaws.com/mivstorage/app/public/avatars/32/thumb/thumb.png"></noscript></span>
            <blockquote className="testimonial" itemProp="review">
                <span className="bqstart">“</span>I have worked with a number of front-end engineers over the years and Marcos is top class. He's smart, quick, and delivers on time. To boot, he's also a great guy who works well on a team. Critically, if there's something about a wireframe or requirement that is unclear, he'll first spend some time trying to figure it out, but if it doesn't resolve, he'll come back to ask for clarification. This time-saving balance is hard to achieve and Marcos does it very well.
            </blockquote>
            <h2 className="h4 testimonial-title" itemProp="reviewedBy">Ben Rigby, CEO of Sparked.com</h2><span className="testimonial-line active"><b></b></span>
        </aside>
    </div>
</div>
*/
